/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSortExpressions,
    KvintaSortExpressionsFromJSON,
    KvintaSortExpressionsFromJSONTyped,
    KvintaSortExpressionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaPaging
 */
export interface KvintaPaging {
    /**
     * 
     * @type {number}
     * @memberof KvintaPaging
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaPaging
     */
    size?: number;
    /**
     * 
     * @type {KvintaSortExpressions}
     * @memberof KvintaPaging
     */
    sort?: KvintaSortExpressions;
}

export function KvintaPagingFromJSON(json: any): KvintaPaging {
    return KvintaPagingFromJSONTyped(json, false);
}

export function KvintaPagingFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaPaging {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : KvintaSortExpressionsFromJSON(json['sort']),
    };
}

export function KvintaPagingToJSON(value?: KvintaPaging | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'sort': KvintaSortExpressionsToJSON(value.sort),
    };
}


