/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateMobileAppAssignmentRequest
 */
export interface KvintaCreateMobileAppAssignmentRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateMobileAppAssignmentRequest
     */
    appName: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateMobileAppAssignmentRequest
     */
    appVersion: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateMobileAppAssignmentRequest
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateMobileAppAssignmentRequest
     */
    email: string;
}

export function KvintaCreateMobileAppAssignmentRequestFromJSON(json: any): KvintaCreateMobileAppAssignmentRequest {
    return KvintaCreateMobileAppAssignmentRequestFromJSONTyped(json, false);
}

export function KvintaCreateMobileAppAssignmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateMobileAppAssignmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appName': json['appName'],
        'appVersion': json['appVersion'],
        'deviceId': json['deviceId'],
        'email': json['email'],
    };
}

export function KvintaCreateMobileAppAssignmentRequestToJSON(value?: KvintaCreateMobileAppAssignmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appName': value.appName,
        'appVersion': value.appVersion,
        'deviceId': value.deviceId,
        'email': value.email,
    };
}


