/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaAppVersion
 */
export interface KvintaAppVersion {
    /**
     * 
     * @type {string}
     * @memberof KvintaAppVersion
     */
    applicationName: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAppVersion
     */
    applicationVersion: string;
}

export function KvintaAppVersionFromJSON(json: any): KvintaAppVersion {
    return KvintaAppVersionFromJSONTyped(json, false);
}

export function KvintaAppVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAppVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationName': json['applicationName'],
        'applicationVersion': json['applicationVersion'],
    };
}

export function KvintaAppVersionToJSON(value?: KvintaAppVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationName': value.applicationName,
        'applicationVersion': value.applicationVersion,
    };
}


