function generateExpirationString(days?: number) {
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    return `; expires=${date.toUTCString()}`;
  } else {
    return '';
  }
}
export function setCookie({ baseDomain, cookieName }, token: string, days: number) {
  console.log(
    'Setting cookie: ',
    `${cookieName}=${token || ''}${generateExpirationString(days)}; domain=.${baseDomain}; path=/`,
  );
  document.cookie = `${cookieName}=${token || ''}${generateExpirationString(days)}; domain=.${baseDomain}; path=/`;
  const c = document.cookie;
  console.log('Current cookie', c);
}
