import * as React from 'react';
import { Map as OpenlayersMap, control, style, proj, Feature, geom, source, layer, View } from 'openlayers';

// const posViena = proj.fromLonLat([16.3725, 48.208889]);

export interface IKvSinglePointMapProps {
  delay?: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  size?: { height: string; width: string };
  targetId: string;
  markerLocation: [number, number];
}

export class Map extends React.Component<IKvSinglePointMapProps, any> {
  private mapMarkerTimeout: number | undefined = undefined;
  private _map: OpenlayersMap | null = null;
  private _vectorLayer: layer.Vector | undefined = undefined;

  componentDidMount() {
    this.mapMarkerTimeout = window.setTimeout(() => {
      this.setUpMap(this.props);
      this.setMarkers(this.props);
      if (this._map !== null) {
        this._map.setTarget(this.props.targetId);
      }
    }, this.props.delay || 0);
  }

  componentWillUnmount() {
    try {
      clearTimeout(this.mapMarkerTimeout);
      this._map = null;
    } catch (e) {
      // setTarget may not always be available.
      console.error('map was not able to set target');
    }
  }

  setMarkers(props: IKvSinglePointMapProps) {
    if (!props.markerLocation) {
      // no markers, no need to do anything.
      return;
    }
    // create the style
    const iconStyle = new style.Style({
      image: new style.Icon(
        /** @type {olx.style.IconOptions} */ {
          anchor: [0.5, 1],
          anchorOrigin: 'bottom-right',
          scale: 0.4,
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          opacity: 0.9,
          src: 'https://unpkg.com/leaflet@1.3.1/dist/images/marker-icon-2x.png',
        },
      ),
    });

    const feature = new Feature({
      geometry: new geom.Point(proj.fromLonLat(props.markerLocation)),
      name: `Location${props.targetId}`,
      id: `location${props.targetId}`,
    });

    const vectorSource = new source.Vector({ features: [feature] });
    if (this._vectorLayer && this._map) {
      // remove previous marker, since this is single marker.
      this._map.removeLayer(this._vectorLayer);
    }
    this._vectorLayer = new layer.Vector({
      source: vectorSource,
      style: iconStyle,
    });
    if (this._map !== null) {
      this._map.addLayer(this._vectorLayer);
      this._map.getView().fit(vectorSource.getExtent(), undefined);
    }
  }

  setUpMap(props: IKvSinglePointMapProps) {
    this._map = new OpenlayersMap({
      layers: [new layer.Tile({ source: new source.OSM() })],
      view: new View({
        center: proj.fromLonLat(props.markerLocation || [0, 0]),
        zoom: props.zoom || 1,
        maxZoom: props.maxZoom || 12,
        minZoom: props.minZoom || 1,
      }),
      controls: [new control.Zoom()],
    });
  }

  render() {
    if (this._map) {
      this._map.getView().setCenter(proj.fromLonLat(this.props.markerLocation));
      this._map.getView().setZoom(12);
      this.setMarkers(this.props);
    }
    return (
      <div id={this.props.targetId} style={this.props.size ? this.props.size : { height: '150px', width: '150px' }} />
    );
  }
}
