/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcReportingStatusType {
    Saved = 'SAVED',
    BlockedByDependees = 'BLOCKED_BY_DEPENDEES',
    ReleaseQueued = 'RELEASE_QUEUED',
    ReleaseOk = 'RELEASE_OK',
    ReleaseError = 'RELEASE_ERROR',
    Done = 'DONE',
    Rejected = 'REJECTED'
}

export function KvintaEpcReportingStatusTypeFromJSON(json: any): KvintaEpcReportingStatusType {
    return KvintaEpcReportingStatusTypeFromJSONTyped(json, false);
}

export function KvintaEpcReportingStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcReportingStatusType {
    return json as KvintaEpcReportingStatusType;
}

export function KvintaEpcReportingStatusTypeToJSON(value?: KvintaEpcReportingStatusType | null): any {
    return value as any;
}

