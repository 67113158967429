export const countries = [
  { shortCode: 'AF', countryName: 'country.af' },
  { shortCode: 'AX', countryName: 'country.ax' },
  { shortCode: 'AL', countryName: 'country.al' },
  { shortCode: 'DZ', countryName: 'country.dz' },
  { shortCode: 'AS', countryName: 'country.as' },
  { shortCode: 'AD', countryName: 'country.ad' },
  { shortCode: 'AO', countryName: 'country.ao' },
  { shortCode: 'AI', countryName: 'country.ai' },
  { shortCode: 'AQ', countryName: 'country.aq' },
  { shortCode: 'AG', countryName: 'country.ag' },
  { shortCode: 'AR', countryName: 'country.ar' },
  { shortCode: 'AM', countryName: 'country.am' },
  { shortCode: 'AW', countryName: 'country.aw' },
  { shortCode: 'AU', countryName: 'country.au' },
  { shortCode: 'AT', countryName: 'country.at' },
  { shortCode: 'AZ', countryName: 'country.az' },
  { shortCode: 'BS', countryName: 'country.bs' },
  { shortCode: 'BH', countryName: 'country.bh' },
  { shortCode: 'BD', countryName: 'country.bd' },
  { shortCode: 'BB', countryName: 'country.bb' },
  { shortCode: 'BY', countryName: 'country.by' },
  { shortCode: 'BE', countryName: 'country.be' },
  { shortCode: 'BZ', countryName: 'country.bz' },
  { shortCode: 'BJ', countryName: 'country.bj' },
  { shortCode: 'BM', countryName: 'country.bm' },
  { shortCode: 'BT', countryName: 'country.bt' },
  { shortCode: 'BO', countryName: 'country.bo' },
  { shortCode: 'BQ', countryName: 'country.bq' },
  { shortCode: 'BA', countryName: 'country.ba' },
  { shortCode: 'BW', countryName: 'country.bw' },
  { shortCode: 'BV', countryName: 'country.bv' },
  { shortCode: 'BR', countryName: 'country.br' },
  { shortCode: 'IO', countryName: 'country.io' },
  { shortCode: 'BN', countryName: 'country.bn' },
  { shortCode: 'BG', countryName: 'country.bg' },
  { shortCode: 'BF', countryName: 'country.bf' },
  { shortCode: 'BI', countryName: 'country.bi' },
  { shortCode: 'CV', countryName: 'country.cv' },
  { shortCode: 'KH', countryName: 'country.kh' },
  { shortCode: 'CM', countryName: 'country.cm' },
  { shortCode: 'CA', countryName: 'country.ca' },
  { shortCode: 'KY', countryName: 'country.ky' },
  { shortCode: 'CF', countryName: 'country.cf' },
  { shortCode: 'TD', countryName: 'country.td' },
  { shortCode: 'CL', countryName: 'country.cl' },
  { shortCode: 'CN', countryName: 'country.cn' },
  { shortCode: 'CX', countryName: 'country.cx' },
  { shortCode: 'CC', countryName: 'country.cc' },
  { shortCode: 'CO', countryName: 'country.co' },
  { shortCode: 'KM', countryName: 'country.km' },
  { shortCode: 'CG', countryName: 'country.cg' },
  { shortCode: 'CD', countryName: 'country.cd' },
  { shortCode: 'CK', countryName: 'country.ck' },
  { shortCode: 'CR', countryName: 'country.cr' },
  { shortCode: 'CI', countryName: 'country.ci' },
  { shortCode: 'HR', countryName: 'country.hr' },
  { shortCode: 'CU', countryName: 'country.cu' },
  { shortCode: 'CW', countryName: 'country.cw' },
  { shortCode: 'CY', countryName: 'country.cy' },
  { shortCode: 'CZ', countryName: 'country.cz' },
  { shortCode: 'DK', countryName: 'country.dk' },
  { shortCode: 'DJ', countryName: 'country.dj' },
  { shortCode: 'DM', countryName: 'country.dm' },
  { shortCode: 'DO', countryName: 'country.do' },
  { shortCode: 'EC', countryName: 'country.ec' },
  { shortCode: 'EG', countryName: 'country.eg' },
  { shortCode: 'SV', countryName: 'country.sv' },
  { shortCode: 'GQ', countryName: 'country.gq' },
  { shortCode: 'ER', countryName: 'country.er' },
  { shortCode: 'EE', countryName: 'country.ee' },
  { shortCode: 'SZ', countryName: 'country.sz' },
  { shortCode: 'ET', countryName: 'country.et' },
  { shortCode: 'FK', countryName: 'country.fk' },
  { shortCode: 'FO', countryName: 'country.fo' },
  { shortCode: 'FJ', countryName: 'country.fj' },
  { shortCode: 'FI', countryName: 'country.fi' },
  { shortCode: 'FR', countryName: 'country.fr' },
  { shortCode: 'GF', countryName: 'country.gf' },
  { shortCode: 'PF', countryName: 'country.pf' },
  { shortCode: 'TF', countryName: 'country.tf' },
  { shortCode: 'GA', countryName: 'country.ga' },
  { shortCode: 'GM', countryName: 'country.gm' },
  { shortCode: 'GE', countryName: 'country.ge' },
  { shortCode: 'DE', countryName: 'country.de' },
  { shortCode: 'GH', countryName: 'country.gh' },
  { shortCode: 'GI', countryName: 'country.gi' },
  { shortCode: 'GR', countryName: 'country.gr' },
  { shortCode: 'GL', countryName: 'country.gl' },
  { shortCode: 'GD', countryName: 'country.gd' },
  { shortCode: 'GP', countryName: 'country.gp' },
  { shortCode: 'GU', countryName: 'country.gu' },
  { shortCode: 'GT', countryName: 'country.gt' },
  { shortCode: 'GG', countryName: 'country.gg' },
  { shortCode: 'GN', countryName: 'country.gn' },
  { shortCode: 'GW', countryName: 'country.gw' },
  { shortCode: 'GY', countryName: 'country.gy' },
  { shortCode: 'HT', countryName: 'country.ht' },
  { shortCode: 'HM', countryName: 'country.hm' },
  { shortCode: 'VA', countryName: 'country.va' },
  { shortCode: 'HN', countryName: 'country.hn' },
  { shortCode: 'HK', countryName: 'country.hk' },
  { shortCode: 'HU', countryName: 'country.hu' },
  { shortCode: 'IS', countryName: 'country.is' },
  { shortCode: 'IN', countryName: 'country.in' },
  { shortCode: 'ID', countryName: 'country.id' },
  { shortCode: 'IR', countryName: 'country.ir' },
  { shortCode: 'IQ', countryName: 'country.iq' },
  { shortCode: 'IE', countryName: 'country.ie' },
  { shortCode: 'IM', countryName: 'country.im' },
  { shortCode: 'IL', countryName: 'country.il' },
  { shortCode: 'IT', countryName: 'country.it' },
  { shortCode: 'JM', countryName: 'country.jm' },
  { shortCode: 'JP', countryName: 'country.jp' },
  { shortCode: 'JE', countryName: 'country.je' },
  { shortCode: 'JO', countryName: 'country.jo' },
  { shortCode: 'KZ', countryName: 'country.kz' },
  { shortCode: 'KE', countryName: 'country.ke' },
  { shortCode: 'KI', countryName: 'country.ki' },
  { shortCode: 'KP', countryName: 'country.kp' },
  { shortCode: 'KR', countryName: 'country.kr' },
  { shortCode: 'KW', countryName: 'country.kw' },
  { shortCode: 'KG', countryName: 'country.kg' },
  { shortCode: 'LA', countryName: 'country.la' },
  { shortCode: 'LV', countryName: 'country.lv' },
  { shortCode: 'LB', countryName: 'country.lb' },
  { shortCode: 'LS', countryName: 'country.ls' },
  { shortCode: 'LR', countryName: 'country.lr' },
  { shortCode: 'LY', countryName: 'country.ly' },
  { shortCode: 'LI', countryName: 'country.li' },
  { shortCode: 'LT', countryName: 'country.lt' },
  { shortCode: 'LU', countryName: 'country.lu' },
  { shortCode: 'MO', countryName: 'country.mo' },
  { shortCode: 'MG', countryName: 'country.mg' },
  { shortCode: 'MW', countryName: 'country.mw' },
  { shortCode: 'MY', countryName: 'country.my' },
  { shortCode: 'MV', countryName: 'country.mv' },
  { shortCode: 'ML', countryName: 'country.ml' },
  { shortCode: 'MT', countryName: 'country.mt' },
  { shortCode: 'MH', countryName: 'country.mh' },
  { shortCode: 'MQ', countryName: 'country.mq' },
  { shortCode: 'MR', countryName: 'country.mr' },
  { shortCode: 'MU', countryName: 'country.mu' },
  { shortCode: 'YT', countryName: 'country.yt' },
  { shortCode: 'MX', countryName: 'country.mx' },
  { shortCode: 'FM', countryName: 'country.fm' },
  { shortCode: 'MD', countryName: 'country.md' },
  { shortCode: 'MC', countryName: 'country.mc' },
  { shortCode: 'MN', countryName: 'country.mn' },
  { shortCode: 'ME', countryName: 'country.me' },
  { shortCode: 'MS', countryName: 'country.ms' },
  { shortCode: 'MA', countryName: 'country.ma' },
  { shortCode: 'MZ', countryName: 'country.mz' },
  { shortCode: 'MM', countryName: 'country.mm' },
  { shortCode: 'NA', countryName: 'country.na' },
  { shortCode: 'NR', countryName: 'country.nr' },
  { shortCode: 'NP', countryName: 'country.np' },
  { shortCode: 'NL', countryName: 'country.nl' },
  { shortCode: 'NC', countryName: 'country.nc' },
  { shortCode: 'NZ', countryName: 'country.nz' },
  { shortCode: 'NI', countryName: 'country.ni' },
  { shortCode: 'NE', countryName: 'country.ne' },
  { shortCode: 'NG', countryName: 'country.ng' },
  { shortCode: 'NU', countryName: 'country.nu' },
  { shortCode: 'NF', countryName: 'country.nf' },
  { shortCode: 'MK', countryName: 'country.mk' },
  { shortCode: 'MP', countryName: 'country.mp' },
  { shortCode: 'NO', countryName: 'country.no' },
  { shortCode: 'OM', countryName: 'country.om' },
  { shortCode: 'PK', countryName: 'country.pk' },
  { shortCode: 'PW', countryName: 'country.pw' },
  { shortCode: 'PS', countryName: 'country.ps' },
  { shortCode: 'PA', countryName: 'country.pa' },
  { shortCode: 'PG', countryName: 'country.pg' },
  { shortCode: 'PY', countryName: 'country.py' },
  { shortCode: 'PE', countryName: 'country.pe' },
  { shortCode: 'PH', countryName: 'country.ph' },
  { shortCode: 'PN', countryName: 'country.pn' },
  { shortCode: 'PL', countryName: 'country.pl' },
  { shortCode: 'PT', countryName: 'country.pt' },
  { shortCode: 'PR', countryName: 'country.pr' },
  { shortCode: 'QA', countryName: 'country.qa' },
  { shortCode: 'RE', countryName: 'country.re' },
  { shortCode: 'RO', countryName: 'country.ro' },
  { shortCode: 'RU', countryName: 'country.ru' },
  { shortCode: 'RW', countryName: 'country.rw' },
  { shortCode: 'BL', countryName: 'country.bl' },
  { shortCode: 'SH', countryName: 'country.sh' },
  { shortCode: 'KN', countryName: 'country.kn' },
  { shortCode: 'LC', countryName: 'country.lc' },
  { shortCode: 'MF', countryName: 'country.mf' },
  { shortCode: 'PM', countryName: 'country.pm' },
  { shortCode: 'VC', countryName: 'country.vc' },
  { shortCode: 'WS', countryName: 'country.ws' },
  { shortCode: 'SM', countryName: 'country.sm' },
  { shortCode: 'ST', countryName: 'country.st' },
  { shortCode: 'SA', countryName: 'country.sa' },
  { shortCode: 'SN', countryName: 'country.sn' },
  { shortCode: 'RS', countryName: 'country.rs' },
  { shortCode: 'SC', countryName: 'country.sc' },
  { shortCode: 'SL', countryName: 'country.sl' },
  { shortCode: 'SG', countryName: 'country.sg' },
  { shortCode: 'SX', countryName: 'country.sx' },
  { shortCode: 'SK', countryName: 'country.sk' },
  { shortCode: 'SI', countryName: 'country.si' },
  { shortCode: 'SB', countryName: 'country.sb' },
  { shortCode: 'SO', countryName: 'country.so' },
  { shortCode: 'ZA', countryName: 'country.za' },
  { shortCode: 'GS', countryName: 'country.gs' },
  { shortCode: 'SS', countryName: 'country.ss' },
  { shortCode: 'ES', countryName: 'country.es' },
  { shortCode: 'LK', countryName: 'country.lk' },
  { shortCode: 'SD', countryName: 'country.sd' },
  { shortCode: 'SR', countryName: 'country.sr' },
  { shortCode: 'SJ', countryName: 'country.sj' },
  { shortCode: 'SE', countryName: 'country.se' },
  { shortCode: 'CH', countryName: 'country.ch' },
  { shortCode: 'SY', countryName: 'country.sy' },
  { shortCode: 'TW', countryName: 'country.tw' },
  { shortCode: 'TJ', countryName: 'country.tj' },
  { shortCode: 'TZ', countryName: 'country.tz' },
  { shortCode: 'TH', countryName: 'country.th' },
  { shortCode: 'TL', countryName: 'country.tl' },
  { shortCode: 'TG', countryName: 'country.tg' },
  { shortCode: 'TK', countryName: 'country.tk' },
  { shortCode: 'TO', countryName: 'country.to' },
  { shortCode: 'TT', countryName: 'country.tt' },
  { shortCode: 'TN', countryName: 'country.tn' },
  { shortCode: 'TR', countryName: 'country.tr' },
  { shortCode: 'TM', countryName: 'country.tm' },
  { shortCode: 'TC', countryName: 'country.tc' },
  { shortCode: 'TV', countryName: 'country.tv' },
  { shortCode: 'UG', countryName: 'country.ug' },
  { shortCode: 'UA', countryName: 'country.ua' },
  { shortCode: 'AE', countryName: 'country.ae' },
  { shortCode: 'GB', countryName: 'country.gb' },
  { shortCode: 'US', countryName: 'country.us' },
  { shortCode: 'UM', countryName: 'country.um' },
  { shortCode: 'UY', countryName: 'country.uy' },
  { shortCode: 'UZ', countryName: 'country.uz' },
  { shortCode: 'VU', countryName: 'country.vu' },
  { shortCode: 'VE', countryName: 'country.ve' },
  { shortCode: 'VN', countryName: 'country.vn' },
  { shortCode: 'VG', countryName: 'country.vg' },
  { shortCode: 'VI', countryName: 'country.vi' },
  { shortCode: 'WF', countryName: 'country.wf' },
  { shortCode: 'EH', countryName: 'country.eh' },
  { shortCode: 'YE', countryName: 'country.ye' },
  { shortCode: 'ZM', countryName: 'country.zm' },
  { shortCode: 'ZW', countryName: 'country.zw' },
];
