import { TooltipWithBounds } from '@visx/tooltip';
import * as React from 'react';

export interface TooltipMenuItem {
  onClick: (e: any, nodeId: string) => void;
  text: string;
  nodeId: string;
}

export const TooltipMenuItem = ({ onClick, text, nodeId }: TooltipMenuItem) => (
  <div className="tooltip-link" onClick={(e) => onClick(e, nodeId)}>
    {text}
  </div>
);

export interface TooltipMenuLinkProps {
  text: string;
  href: string;
}
export const TooltipMenuLink = ({ text, href }: TooltipMenuLinkProps) => (
  <div className="tooltip-html-link">
    <a href={href}>{text}</a>
  </div>
);

export interface TooltipTextProps {
  text: string;
}
export const TooltipText = ({ text }: TooltipTextProps) => <div className="tooltip-text">{text}</div>;

export interface TooltipBasProps {
  nodeId: string;
  children: any;
}
export const TooltipBase = ({ nodeId, children }: TooltipBasProps) => {
  return (
    <div className="tooltip-content">
      <p className="tooltip-content-title">{nodeId}</p>
      {children}
    </div>
  );
};

export interface TooltipProps {
  tooltipLeft: number;
  tooltipTop: number;
  tooltipData: any;
}

export const Tooltip = ({ tooltipLeft, tooltipTop, tooltipData }: TooltipProps) => (
  <TooltipWithBounds
    unstyled={true}
    applyPositionStyle={true}
    key={Math.random()} // needed for bounds to update correctly
    left={tooltipLeft}
    top={tooltipTop}
    className="tooltip"
  >
    {tooltipData}
  </TooltipWithBounds>
);
