/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMobileAppAssignment,
    KvintaMobileAppAssignmentFromJSON,
    KvintaMobileAppAssignmentFromJSONTyped,
    KvintaMobileAppAssignmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultMobileAppAssignment
 */
export interface KvintaGetListResultMobileAppAssignment {
    /**
     * 
     * @type {Array<KvintaMobileAppAssignment>}
     * @memberof KvintaGetListResultMobileAppAssignment
     */
    list?: Array<KvintaMobileAppAssignment> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultMobileAppAssignment
     */
    total?: number | null;
}

export function KvintaGetListResultMobileAppAssignmentFromJSON(json: any): KvintaGetListResultMobileAppAssignment {
    return KvintaGetListResultMobileAppAssignmentFromJSONTyped(json, false);
}

export function KvintaGetListResultMobileAppAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultMobileAppAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaMobileAppAssignmentFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultMobileAppAssignmentToJSON(value?: KvintaGetListResultMobileAppAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaMobileAppAssignmentToJSON)),
        'total': value.total,
    };
}


