/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
    KvintaDeviceFilter,
    KvintaDeviceFilterFromJSON,
    KvintaDeviceFilterFromJSONTyped,
    KvintaDeviceFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
    KvintaSortExpressions,
    KvintaSortExpressionsFromJSON,
    KvintaSortExpressionsFromJSONTyped,
    KvintaSortExpressionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListDevicesRequest
 */
export interface KvintaListDevicesRequest {
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaListDevicesRequest
     */
    dateTimeRange?: KvintaDateTimeRange;
    /**
     * 
     * @type {KvintaDeviceFilter}
     * @memberof KvintaListDevicesRequest
     */
    filter?: KvintaDeviceFilter;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListDevicesRequest
     */
    pagination: KvintaPaging;
    /**
     * 
     * @type {KvintaSortExpressions}
     * @memberof KvintaListDevicesRequest
     */
    sort?: KvintaSortExpressions;
}

export function KvintaListDevicesRequestFromJSON(json: any): KvintaListDevicesRequest {
    return KvintaListDevicesRequestFromJSONTyped(json, false);
}

export function KvintaListDevicesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListDevicesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateTimeRange': !exists(json, 'dateTimeRange') ? undefined : KvintaDateTimeRangeFromJSON(json['dateTimeRange']),
        'filter': !exists(json, 'filter') ? undefined : KvintaDeviceFilterFromJSON(json['filter']),
        'pagination': KvintaPagingFromJSON(json['pagination']),
        'sort': !exists(json, 'sort') ? undefined : KvintaSortExpressionsFromJSON(json['sort']),
    };
}

export function KvintaListDevicesRequestToJSON(value?: KvintaListDevicesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateTimeRange': KvintaDateTimeRangeToJSON(value.dateTimeRange),
        'filter': KvintaDeviceFilterToJSON(value.filter),
        'pagination': KvintaPagingToJSON(value.pagination),
        'sort': KvintaSortExpressionsToJSON(value.sort),
    };
}


