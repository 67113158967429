import {
  ENodeType,
  THierarchyHistoryChildNodeParams,
  THierarchyHistoryChildrenNodeParams,
  THierarchyHistoryLinkParams,
  THierarchyHistoryNodeParams,
  THierarchyHistoryParentNodeParams,
  THierarchyHistoryRestNodeParams,
  THierarchyHistoryTrackedNodeParams,
  TSimpleHierarchyHistory,
  TSimpleHierarchyHistoryPoint,
  TSimpleHierarchyNode,
} from './types';

export function measureText({ fontSize, text }: { fontSize: number; text: string }): { width: number; height: number } {
  const textDimensions = { width: 0, height: 0 };

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = `${fontSize}px Roboto`;
    const { width, actualBoundingBoxAscent } = ctx.measureText(text);
    textDimensions.width = Math.ceil(width) * 1.11;
    textDimensions.height = actualBoundingBoxAscent;
  }
  return textDimensions;
}

export function generateTimelinePoints(data: TSimpleHierarchyHistory) {
  return data.map((hierarchyHistoryPoint) => {
    return { id: hierarchyHistoryPoint.timestamp.toISOString(), date: hierarchyHistoryPoint.timestamp.toISOString() };
  });
}

function calculateTrackedNodeNumberOfChildrenParams(
  numberOfChildren,
  numberOfChildrenFontSize,
): {
  numberOfChildrenTextDy: number | undefined;
  numberOfChildrenText: string | undefined;
} {
  if (numberOfChildren > 0) {
    const numberOfChildrenTextDimensions = measureText({
      fontSize: numberOfChildrenFontSize,
      text: numberOfChildren.toString(),
    });
    const numberOfChildrenTextDy = numberOfChildrenFontSize - numberOfChildrenTextDimensions.height;
    const numberOfChildrenText = numberOfChildren.toString();

    return {
      numberOfChildrenTextDy,
      numberOfChildrenText,
    };
  } else {
    return {
      numberOfChildrenTextDy: undefined,
      numberOfChildrenText: undefined,
    };
  }
}

export function calculateTrackedNodeParams(
  data: TSimpleHierarchyNode,
  svgWidth: number,
  svgHeight: number,
  numberOfChildren: number,
) {
  const fontSize = 12;
  const numberOfChildrenFontSize = 8;
  const verticalPadding = 13;
  const horizontalPadding = 16;

  const textDimensions = measureText({ fontSize, text: data.id });

  const width = textDimensions.width + horizontalPadding * 2;
  const height = fontSize + verticalPadding * 2;
  const radius = 11.5;

  const centerX = -width / 2;
  const centerY = -height / 2;

  const y = svgHeight / 2;
  const x = svgWidth / 2;

  const params = {
    nodeType: ENodeType.TRACKED as const,
    x,
    y,
    width,
    height,
    centerX,
    centerY,
    radius,
    fontSize,
    text: data.id,
    textDy: fontSize - textDimensions.height + 2,
    id: data.id,
  };

  const numberOfChildrenParams = calculateTrackedNodeNumberOfChildrenParams(numberOfChildren, numberOfChildrenFontSize);

  return {
    ...params,
    ...numberOfChildrenParams,
  };
}

export function calculateParentNodeParams(data: TSimpleHierarchyNode | undefined, svgWidth: number, svgHeight: number) {
  if (data) {
    const fontSize = 12;
    const verticalPadding = 7;
    const horizontalPadding = 10;

    const textDimensions = measureText({ fontSize, text: data.id });
    const width = textDimensions.width + horizontalPadding * 2;
    const height = fontSize + verticalPadding * 2;

    const centerX = -width / 2;
    const centerY = -height / 2;

    const y = svgHeight / 2;
    const x = svgWidth / 6;

    return {
      nodeType: ENodeType.PARENT as const,
      x,
      y,
      width,
      height,
      centerX,
      centerY,
      fontSize,
      text: data.id,
      textDy: fontSize - textDimensions.height,
      id: data.id,
    };
  } else {
    return undefined;
  }
}

function findMaxChildDimensions(data: TSimpleHierarchyNode[]) {
  return data.reduce(
    (acc, child) => {
      const { width: textWidth, height: textHeight } = measureText({ fontSize: 12, text: child.id });

      return acc.maxTextWidth < textWidth ? { maxTextWidth: textWidth, textHeight } : acc;
    },
    { maxTextWidth: 0, textHeight: 0 },
  );
}

type TCalculateChildrenParams = {
  data: TSimpleHierarchyNode[];
  maxChildren: number;
  svgDimensions: { svgWidth: number; svgHeight: number };
  maxChildDimensions: { maxTextWidth: number; textHeight: number };
};

export function calculateChildrenParams({
  data,
  maxChildren,
  svgDimensions,
  maxChildDimensions,
}: TCalculateChildrenParams): THierarchyHistoryChildrenNodeParams[] {
  const { svgWidth, svgHeight } = svgDimensions;
  const { maxTextWidth, textHeight } = maxChildDimensions;

  const childrenPaginated: (THierarchyHistoryRestNodeParams | THierarchyHistoryChildNodeParams)[][] = [];

  const fontSize = 12;
  const verticalPadding = 7;
  const horizontalPadding = 10;

  const width = maxTextWidth + horizontalPadding * 2;
  const height = fontSize + verticalPadding * 2;

  const centerX = -width / 2;
  const centerY = -height / 2;

  const heightUnit = svgHeight / maxChildren / 2;

  const baseChildNodeParams = {
    width,
    height,
    centerX,
    centerY,
    fontSize,
    textDy: fontSize - textHeight,
  };

  if (data.length > maxChildren) {
    let start = 0;
    while (start < data.length) {
      const page: (THierarchyHistoryChildNodeParams | THierarchyHistoryRestNodeParams)[] = [];

      const length = childrenPaginated.length === 0 ? maxChildren - 1 : maxChildren - 2;
      const end = start + length;

      if (start > 0) {
        const prevPageNode = {
          ...baseChildNodeParams,
          id: 'previous-nodes',
          text: `Show previous (${start})`,
          y: heightUnit,
          x: (svgWidth / 6) * 5,
          key: `${childrenPaginated.length}_prev`,
          nodeType: ENodeType.TO_PREV_PAGE as const,
        };

        page.push(prevPageNode);
      }

      data.slice(start, end).forEach((childData, index) => {
        const y = start > 0 ? heightUnit + heightUnit * 2 * (index + 1) : heightUnit + heightUnit * 2 * index;

        page.push({
          ...baseChildNodeParams,
          id: childData.id,
          text: childData.id,
          y: y,
          x: (svgWidth / 6) * 5,
          key: childData.id,
          nodeType: ENodeType.CHILD as const,
        });
      });

      if (end < data.length) {
        const nextPageNode = {
          ...baseChildNodeParams,
          id: 'next-nodes',
          text: `Show next (${data.length - end})`,
          y: heightUnit * (maxChildren * 2 - 1),
          x: (svgWidth / 6) * 5,
          key: `${childrenPaginated.length}_next`,
          nodeType: ENodeType.TO_NEXT_PAGE as const,
        };
        page.push(nextPageNode);
      }

      childrenPaginated.push(page);
      start = end;
    }
    return childrenPaginated;
  } else {
    const childrenMargin = 20;
    const childrenBlockOverallHeight = data.length * height + childrenMargin * (data.length - 1);
    const childrenBlockY = (svgHeight - childrenBlockOverallHeight) / 2;

    const children: THierarchyHistoryChildNodeParams[] = data.slice(0, maxChildren - 1).map((childData, index) => {
      return {
        ...baseChildNodeParams,
        id: childData.id,
        text: childData.id,
        y: childrenBlockY + height / 2 + index * height + index * childrenMargin,
        x: (svgWidth / 6) * 5,
        key: childData.id,
        nodeType: ENodeType.CHILD as const,
      };
    });

    return [children];
  }
}

function calculateLinkParams(
  tracked: THierarchyHistoryTrackedNodeParams,
  parent: THierarchyHistoryParentNodeParams | undefined,
  children: THierarchyHistoryChildrenNodeParams,
) {
  const links: THierarchyHistoryLinkParams[] = [];

  const trackedStart = {
    x1: tracked.x + tracked.width / 2 + tracked.radius,
    y1: tracked.y,
  };

  if (parent) {
    const parentToTracked = {
      x1: parent.x + parent.width / 2,
      y1: parent.y,
      x2: tracked.x - tracked.width / 2,
      y2: tracked.y,
      key: `${parent.id}-${tracked.id}`,
    };
    links.push(parentToTracked);
  }

  if (children) {
    children.forEach((childParams) => {
      links.push({
        ...trackedStart,
        x2: childParams.x - childParams.width / 2,
        y2: childParams.y,
        key: `${tracked.id}-${childParams.id}`,
      });
    });
  }

  return links;
}

function calculateHierarchyHistoryPointParams(
  { hierarchy }: TSimpleHierarchyHistoryPoint,
  width: number,
  height: number,
  maxChildren: number,
) {
  const numberOfChildren = hierarchy.children ? hierarchy.children.length : 0;
  const tracked = calculateTrackedNodeParams(hierarchy.tracked, width, height, numberOfChildren);
  const parent = calculateParentNodeParams(hierarchy.parent, width, height);

  const children = calculateChildrenParams({
    data: hierarchy.children || [],
    maxChildren,
    svgDimensions: { svgWidth: width, svgHeight: height },
    maxChildDimensions: findMaxChildDimensions(hierarchy.children || []),
  });

  return children.map((childrenPage) => ({
    tracked,
    parent,
    children: childrenPage,
    links: calculateLinkParams(tracked, parent, childrenPage),
  }));
}

export function calculateHierarchyHistoryParams(
  data: TSimpleHierarchyHistory,
  width: number,
  height: number,
  maxChildren: number,
): { [timelinePointId: string]: THierarchyHistoryNodeParams[] } {
  return data.reduce((acc, historyPoint) => {
    acc[historyPoint.timestamp.toISOString()] = calculateHierarchyHistoryPointParams(
      historyPoint,
      width,
      height,
      maxChildren,
    );
    return acc;
  }, {});
}

export function generateHierarchy({ width, height, hierarchyHistoryData }) {
  const hierarchyViewMargin = 20;

  const svgWidth = width - hierarchyViewMargin;
  const svgHeight = height - 2 * hierarchyViewMargin;
  const maxChildren = Math.floor(svgHeight / 50);
  return calculateHierarchyHistoryParams(hierarchyHistoryData, svgWidth, svgHeight, maxChildren);
}
