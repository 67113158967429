/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaMobileAppAssignmentResponse
 */
export interface KvintaMobileAppAssignmentResponse {
    /**
     * 
     * @type {string}
     * @memberof KvintaMobileAppAssignmentResponse
     */
    downloadLink?: string | null;
}

export function KvintaMobileAppAssignmentResponseFromJSON(json: any): KvintaMobileAppAssignmentResponse {
    return KvintaMobileAppAssignmentResponseFromJSONTyped(json, false);
}

export function KvintaMobileAppAssignmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMobileAppAssignmentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'downloadLink': !exists(json, 'downloadLink') ? undefined : json['downloadLink'],
    };
}

export function KvintaMobileAppAssignmentResponseToJSON(value?: KvintaMobileAppAssignmentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'downloadLink': value.downloadLink,
    };
}


