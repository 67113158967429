/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAppVersion,
    KvintaAppVersionFromJSON,
    KvintaAppVersionFromJSONTyped,
    KvintaAppVersionToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetApplicationsResponse
 */
export interface KvintaGetApplicationsResponse {
    /**
     * 
     * @type {Array<KvintaAppVersion>}
     * @memberof KvintaGetApplicationsResponse
     */
    applications?: Array<KvintaAppVersion> | null;
}

export function KvintaGetApplicationsResponseFromJSON(json: any): KvintaGetApplicationsResponse {
    return KvintaGetApplicationsResponseFromJSONTyped(json, false);
}

export function KvintaGetApplicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetApplicationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applications': !exists(json, 'applications') ? undefined : (json['applications'] === null ? null : (json['applications'] as Array<any>).map(KvintaAppVersionFromJSON)),
    };
}

export function KvintaGetApplicationsResponseToJSON(value?: KvintaGetApplicationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applications': value.applications === undefined ? undefined : (value.applications === null ? null : (value.applications as Array<any>).map(KvintaAppVersionToJSON)),
    };
}


