import * as React from 'react';
import cn from 'classnames';
import { TEpcMapMainDataReady, TMapPointData } from './types';
import { MapMultiplePoints } from './MapMultiplePoints';
import { FormattedMessage } from 'react-intl';
import { MovementMapStore } from './MovementMapStore';
import { observer } from 'mobx-react';

type MapPointInfoProps = {
  number: string;
  isActive: boolean;
  setActiveMapPointId: (id: string) => void;
} & TMapPointData;

const MapPointInfo: React.FunctionComponent<MapPointInfoProps> = ({
  name,
  gln13,
  timestamp,
  number,
  isActive,
  id,
  setActiveMapPointId,
}: MapPointInfoProps) => {
  return (
    <div
      className={cn('map-point-info', { ['map-point-info-active']: isActive })}
      onClick={() => setActiveMapPointId(id)}
    >
      <div className={cn('map-point-number', { ['map-point-number-active']: isActive })}>{number}</div>
      <div className={'map-point-name'}>{name}</div>
      <div className={'map-point-gln'}>{gln13}</div>
      <div className={'map-point-event-date'}>{new Date(parseInt(timestamp)).toISOString()}</div>
    </div>
  );
};

export interface MapMovementProps {
  movementMapStore: MovementMapStore;
}

const MovementMapImpl: React.FunctionComponent<MapMovementProps> = (props: MapMovementProps) => {
  const { locations, points, activeMapPointId } = props.movementMapStore;
  return (
    <div className={'map-wrapper'}>
      <div className={'map-sidebar'}>
        {locations.length > 1 && (
          <div className={'line-wrapper'}>
            <div className={'line'} />
          </div>
        )}
        <h3 className={'map-sidebar-title'}>
          <FormattedMessage id={'epc.movement-map.location-history'} />
        </h3>
        {locations.map((pointData, i) => (
          <MapPointInfo
            {...pointData}
            isActive={activeMapPointId === pointData.id}
            number={(i + 1).toString()}
            key={pointData.id}
            setActiveMapPointId={props.movementMapStore.setActiveMapPointId}
          />
        ))}
      </div>
      <MapMultiplePoints
        points={points}
        activePointId={activeMapPointId}
        targetId="events-map"
        size={{ height: '100%', width: '100%' }}
        style={'map'}
        setActiveMapPointId={props.movementMapStore.setActiveMapPointId}
      />
    </div>
  );
};

export const MovementMap = observer(MovementMapImpl);
