import { makeObservable, observable, action, computed } from 'mobx';

export interface ResizeEvent {
  width: number;
  height: number;
}

export const MinHeight = 400;
export const DefaultTitleHeight = 70;
export class PageContentStore {
  height: number | undefined;
  width: number | undefined;
  constructor() {
    makeObservable(this, {
      height: observable,
      width: observable,
      updateDimensions: action,
      tableContentHeightDefault: computed,
      tableContentHeightThin: computed,
    });
    this.height = 0;
    this.width = 0;
  }

  updateDimensions(width: number, height: number) {
    this.height = height;
    this.width = width;
    const ev = new CustomEvent('page-resize', {
      detail: { width, height } as ResizeEvent,
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(ev);
  }

  tableContentHeight(titleMargin: number = DefaultTitleHeight, withTitle = true, withFooter = true): number {
    if (!this.height || this.height === NaN || this.height === 0) {
      // console.error('falback to min height: ', MinHeight);
      return getTableHeight(MinHeight, titleMargin, withTitle, withFooter);
    }
    return getTableHeight(this.height, titleMargin, withTitle, withFooter);
  }

  get tableContentHeightDefault(): number {
    if (!this.height || this.height === NaN || this.height === 0) {
      // console.error('falback to min height: ', MinHeight);
      return getTableHeight(MinHeight, DefaultTitleHeight);
    }
    return getTableHeight(this.height, DefaultTitleHeight);
  }

  get tableContentHeightThin(): number {
    if (!this.height || this.height === NaN || this.height === 0) {
      // console.error('falback to min height: ', MinHeight);
      return getTableHeight(MinHeight, DefaultTitleHeight, false, false);
    }
    return getTableHeight(this.height, DefaultTitleHeight, false, false);
  }
}

export function getTableHeight(
  pageContentHeight: number,
  titleMargin: number,
  withTitle = true,
  withFooter = true,
): number {
  return (
    pageContentHeight -
    (withTitle ? 64 : 0) - //  Title
    titleMargin - // Margin Title
    // (withFooter ? 56 : 0) - //  Footer
    2
  ); //  Padding
}
