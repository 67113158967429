import * as React from 'react';
import {
  ENodeType,
  THierarchyHistoryChildNodeParams,
  THierarchyHistoryParentNodeParams,
  THierarchyHistoryRestNodeParams,
  THierarchyHistoryTrackedNodeParams,
} from './types';

export const ParentNode = ({
  x,
  y,
  width,
  height,
  centerX,
  centerY,
  fontSize,
  text,
  textDy,
}: THierarchyHistoryParentNodeParams) => {
  return (
    <g transform={`translate(${x}, ${y})`} className="parent-node">
      <rect data-node-id={text} height={height} width={width} y={centerY} x={centerX} rx={6} />
      <text dy={textDy} fontSize={fontSize}>
        {text}
      </text>
    </g>
  );
};

export const ChildNode = ({
  x,
  y,
  width,
  height,
  centerX,
  centerY,
  fontSize,
  textDy,
  text,
}: THierarchyHistoryChildNodeParams) => {
  return (
    <g transform={`translate(${x}, ${y})`} className="child-node" data-node-id={text} data-node-type={ENodeType.CHILD}>
      <rect
        data-node-id={text}
        data-node-type={ENodeType.CHILD}
        height={height}
        width={width}
        y={centerY}
        x={centerX}
        rx={6}
      />
      <text dy={textDy} fontSize={fontSize}>
        {text}
      </text>
    </g>
  );
};

export const RestNode = ({
  x,
  y,
  width,
  height,
  centerX,
  centerY,
  fontSize,
  textDy,
  text,
  onClick,
}: THierarchyHistoryRestNodeParams & { onClick: () => void }) => {
  return (
    <g transform={`translate(${x}, ${y})`} className="rest-node" onClick={onClick}>
      <rect height={height} width={width - 3} y={centerY + 3} x={centerX + 3} rx={6} />
      <rect height={height} width={width - 3} y={centerY} x={centerX} rx={6} />
      <text dy={textDy} fontSize={fontSize}>
        {text}
      </text>
    </g>
  );
};

export const TrackedNode = ({
  x,
  y,
  width,
  height,
  centerX,
  centerY,
  radius,
  fontSize,
  textDy,
  text,
  numberOfChildrenTextDy,
  numberOfChildrenText,
}: THierarchyHistoryTrackedNodeParams) => {
  return (
    <g
      transform={`translate(${x}, ${y})`}
      className="tracked-node"
      data-node-id={text}
      data-node-type={ENodeType.TRACKED}
    >
      <rect
        data-node-id={text}
        data-node-type={ENodeType.TRACKED}
        height={height}
        width={width}
        y={centerY}
        x={centerX}
        rx={6}
      />
      <text dy={textDy} fontSize={fontSize} className="tracked-node-text">
        {text}
      </text>
      {numberOfChildrenText && (
        <g transform={`translate(${width / 2 + radius})`}>
          <circle r={radius} x={0} />
          <text
            dy={numberOfChildrenTextDy}
            fontSize={8} //fixme!!
            className="number-of-children"
          >
            {numberOfChildrenText}
          </text>
        </g>
      )}
    </g>
  );
};

export type TLinkProps = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const Link: React.FunctionComponent<TLinkProps> = ({ x1, y1, x2, y2 }: TLinkProps) => {
  const bezierParam = (x2 - x1) * 0.3;
  return (
    <path className="link" d={`M ${x1} ${y1} C ${x1 + bezierParam} ${y1} ${x2 - bezierParam} ${y2} ${x2} ${y2}`} />
  );
};
