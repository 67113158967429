/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
    KvintaMobileAppAssignmentFilter,
    KvintaMobileAppAssignmentFilterFromJSON,
    KvintaMobileAppAssignmentFilterFromJSONTyped,
    KvintaMobileAppAssignmentFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
    KvintaSortExpressions,
    KvintaSortExpressionsFromJSON,
    KvintaSortExpressionsFromJSONTyped,
    KvintaSortExpressionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListMobileAppAssignmentRequest
 */
export interface KvintaListMobileAppAssignmentRequest {
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaListMobileAppAssignmentRequest
     */
    dateTimeRange?: KvintaDateTimeRange;
    /**
     * 
     * @type {KvintaMobileAppAssignmentFilter}
     * @memberof KvintaListMobileAppAssignmentRequest
     */
    filter?: KvintaMobileAppAssignmentFilter;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListMobileAppAssignmentRequest
     */
    pagination: KvintaPaging;
    /**
     * 
     * @type {KvintaSortExpressions}
     * @memberof KvintaListMobileAppAssignmentRequest
     */
    sort?: KvintaSortExpressions;
}

export function KvintaListMobileAppAssignmentRequestFromJSON(json: any): KvintaListMobileAppAssignmentRequest {
    return KvintaListMobileAppAssignmentRequestFromJSONTyped(json, false);
}

export function KvintaListMobileAppAssignmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListMobileAppAssignmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateTimeRange': !exists(json, 'dateTimeRange') ? undefined : KvintaDateTimeRangeFromJSON(json['dateTimeRange']),
        'filter': !exists(json, 'filter') ? undefined : KvintaMobileAppAssignmentFilterFromJSON(json['filter']),
        'pagination': KvintaPagingFromJSON(json['pagination']),
        'sort': !exists(json, 'sort') ? undefined : KvintaSortExpressionsFromJSON(json['sort']),
    };
}

export function KvintaListMobileAppAssignmentRequestToJSON(value?: KvintaListMobileAppAssignmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateTimeRange': KvintaDateTimeRangeToJSON(value.dateTimeRange),
        'filter': KvintaMobileAppAssignmentFilterToJSON(value.filter),
        'pagination': KvintaPagingToJSON(value.pagination),
        'sort': KvintaSortExpressionsToJSON(value.sort),
    };
}


