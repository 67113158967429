/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDeviceFilter
 */
export interface KvintaDeviceFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaDeviceFilter
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeviceFilter
     */
    locationGln13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeviceFilter
     */
    serialNumber?: string | null;
}

export function KvintaDeviceFilterFromJSON(json: any): KvintaDeviceFilter {
    return KvintaDeviceFilterFromJSONTyped(json, false);
}

export function KvintaDeviceFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeviceFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'locationGln13': !exists(json, 'locationGln13') ? undefined : json['locationGln13'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
    };
}

export function KvintaDeviceFilterToJSON(value?: KvintaDeviceFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'locationGln13': value.locationGln13,
        'serialNumber': value.serialNumber,
    };
}


