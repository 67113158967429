import { TMapPointData } from './types';

export function pointsDataToGeoJSON(data: TMapPointData[]) {
  return data.map((pointData, i: number) => {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            subtype: pointData.type,
            coordinates: pointData.coordinates,
          },
          properties: {
            id: pointData.id,
            description: pointData.description,
            number: (i + 1).toString(),
          },
        },
      ],
    };
  });
}
