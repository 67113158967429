/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListDevicesRequest,
    KvintaListDevicesRequestFromJSON,
    KvintaListDevicesRequestFromJSONTyped,
    KvintaListDevicesRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListDevicesRequest
 */
export interface KvintaOperationRequestListDevicesRequest {
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListDevicesRequest
     */
    context?: KvintaExecutionContext;
    /**
     * 
     * @type {KvintaListDevicesRequest}
     * @memberof KvintaOperationRequestListDevicesRequest
     */
    input?: KvintaListDevicesRequest;
}

export function KvintaOperationRequestListDevicesRequestFromJSON(json: any): KvintaOperationRequestListDevicesRequest {
    return KvintaOperationRequestListDevicesRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListDevicesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListDevicesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
        'input': !exists(json, 'input') ? undefined : KvintaListDevicesRequestFromJSON(json['input']),
    };
}

export function KvintaOperationRequestListDevicesRequestToJSON(value?: KvintaOperationRequestListDevicesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': KvintaExecutionContextToJSON(value.context),
        'input': KvintaListDevicesRequestToJSON(value.input),
    };
}


