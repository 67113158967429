export enum ENodeType {
  TRACKED = 'tracked',
  PARENT = 'parent',
  CHILD = 'child',
  TO_NEXT_PAGE = 'toNextPage',
  TO_PREV_PAGE = 'toPrevPage',
}

export type TSimpleHierarchyNode = { id: string };

export type TSimpleHierarchy = {
  parent?: TSimpleHierarchyNode;
  tracked: TSimpleHierarchyNode;
  children?: TSimpleHierarchyNode[];
};

export type TSimpleHierarchyHistoryPoint = {
  hierarchy: TSimpleHierarchy;
  timestamp: Date;
};

export type TSimpleHierarchyHistory = Array<TSimpleHierarchyHistoryPoint>;

export type THierarchyHistoryParentNodeParams = {
  nodeType: ENodeType.PARENT;
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  centerX: number;
  centerY: number;
  fontSize: number;
  text: string;
  textDy: number;
};

export type THierarchyHistoryTrackedNodeParams = {
  nodeType: ENodeType.TRACKED;
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  centerX: number;
  centerY: number;
  radius: number;
  fontSize: number;
  textDy: number;
  text: string;
  numberOfChildrenTextDy: number | undefined;
  numberOfChildrenText: string | undefined;
};

export type THierarchyHistoryChildNodeParams = {
  nodeType: ENodeType.CHILD;
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  centerX: number;
  centerY: number;
  fontSize: number;
  textDy: number;
  text: string;
  key: string;
};

export type THierarchyHistoryRestNodeParams = {
  nodeType: ENodeType.TO_PREV_PAGE | ENodeType.TO_NEXT_PAGE;
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  centerX: number;
  centerY: number;
  fontSize: number;
  textDy: number;
  text: string;
  key: string;
};

export type THierarchyHistoryLinkParams = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  key: string;
};

export type THierarchyHistoryChildrenNodeParams =
  | (THierarchyHistoryChildNodeParams | THierarchyHistoryRestNodeParams)[]
  | undefined;

export type THierarchyHistoryNodeParams = {
  parent: THierarchyHistoryParentNodeParams | undefined;
  tracked: THierarchyHistoryTrackedNodeParams;
  children: THierarchyHistoryChildrenNodeParams;
  links: THierarchyHistoryLinkParams[];
};

export type TTimelinePoint = { id: string; date: string };
