/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCreateDeviceRequest
 */
export interface KvintaCreateDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeviceRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeviceRequest
     */
    locationGln13: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCreateDeviceRequest
     */
    serialNumber: string;
}

export function KvintaCreateDeviceRequestFromJSON(json: any): KvintaCreateDeviceRequest {
    return KvintaCreateDeviceRequestFromJSONTyped(json, false);
}

export function KvintaCreateDeviceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCreateDeviceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'locationGln13': json['locationGln13'],
        'serialNumber': json['serialNumber'],
    };
}

export function KvintaCreateDeviceRequestToJSON(value?: KvintaCreateDeviceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'locationGln13': value.locationGln13,
        'serialNumber': value.serialNumber,
    };
}


