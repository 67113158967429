import useDimensions from 'react-cool-dimensions';
import React from 'react';
import { PageContentStore } from './PageContentStore';
import { inject, observer } from 'mobx-react';

interface PageContentProps {
  pageContentStore?: PageContentStore;
}

const PageContentFC: React.FunctionComponent<PageContentProps> = (props: React.PropsWithChildren<PageContentProps>) => {
  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      props.pageContentStore.updateDimensions(width, height);

      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  return (
    <div id="page-content" ref={observe} style={{ overflowY: 'hidden' }}>
      {props.children}
    </div>
  );
};
export const PAGE_CONTENT_STORE_ID = 'pageContentStore';

export const PageContent = inject(PAGE_CONTENT_STORE_ID)(observer(PageContentFC));
