/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Devices Store
 * Swagger for Kvinta Devices Store
 *
 * The version of the OpenAPI document: 0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaOperationRequestCreateDeviceRequest,
    KvintaOperationRequestCreateDeviceRequestFromJSON,
    KvintaOperationRequestCreateDeviceRequestToJSON,
    KvintaOperationRequestCreateMobileAppAssignmentRequest,
    KvintaOperationRequestCreateMobileAppAssignmentRequestFromJSON,
    KvintaOperationRequestCreateMobileAppAssignmentRequestToJSON,
    KvintaOperationRequestDevice,
    KvintaOperationRequestDeviceFromJSON,
    KvintaOperationRequestDeviceToJSON,
    KvintaOperationRequestListDevicesRequest,
    KvintaOperationRequestListDevicesRequestFromJSON,
    KvintaOperationRequestListDevicesRequestToJSON,
    KvintaOperationRequestListMobileAppAssignmentRequest,
    KvintaOperationRequestListMobileAppAssignmentRequestFromJSON,
    KvintaOperationRequestListMobileAppAssignmentRequestToJSON,
    KvintaOperationRequestMobileAppAssignment,
    KvintaOperationRequestMobileAppAssignmentFromJSON,
    KvintaOperationRequestMobileAppAssignmentToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationResponseDevice,
    KvintaOperationResponseDeviceFromJSON,
    KvintaOperationResponseDeviceToJSON,
    KvintaOperationResponseGetApplicationsResponse,
    KvintaOperationResponseGetApplicationsResponseFromJSON,
    KvintaOperationResponseGetApplicationsResponseToJSON,
    KvintaOperationResponseGetListResultDevice,
    KvintaOperationResponseGetListResultDeviceFromJSON,
    KvintaOperationResponseGetListResultDeviceToJSON,
    KvintaOperationResponseGetListResultMobileAppAssignment,
    KvintaOperationResponseGetListResultMobileAppAssignmentFromJSON,
    KvintaOperationResponseGetListResultMobileAppAssignmentToJSON,
    KvintaOperationResponseInteger,
    KvintaOperationResponseIntegerFromJSON,
    KvintaOperationResponseIntegerToJSON,
    KvintaOperationResponseMobileAppAssignment,
    KvintaOperationResponseMobileAppAssignmentFromJSON,
    KvintaOperationResponseMobileAppAssignmentToJSON,
    KvintaOperationResponseMobileAppAssignmentResponse,
    KvintaOperationResponseMobileAppAssignmentResponseFromJSON,
    KvintaOperationResponseMobileAppAssignmentResponseToJSON,
} from '../models';

export interface CreateDeviceRequest {
    kvintaOperationRequestCreateDeviceRequest: KvintaOperationRequestCreateDeviceRequest;
}

export interface CreateMobileAppAssignmentRequest {
    kvintaOperationRequestCreateMobileAppAssignmentRequest: KvintaOperationRequestCreateMobileAppAssignmentRequest;
}

export interface DeleteDeviceRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface DeleteMobileAppAssignmentRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface GetDeviceRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface GetMobileAppAssignmentRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ListDevicesRequest {
    kvintaOperationRequestListDevicesRequest: KvintaOperationRequestListDevicesRequest;
}

export interface ListMobileAppAssignmentsRequest {
    kvintaOperationRequestListMobileAppAssignmentRequest: KvintaOperationRequestListMobileAppAssignmentRequest;
}

export interface SendDownloadLinkRequest {
    kvintaOperationRequestMobileAppAssignment: KvintaOperationRequestMobileAppAssignment;
}

export interface UpdateDeviceRequest {
    kvintaOperationRequestDevice: KvintaOperationRequestDevice;
}

export interface UpdateMobileAppAssignmentRequest {
    kvintaOperationRequestMobileAppAssignment: KvintaOperationRequestMobileAppAssignment;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createDeviceRaw(requestParameters: CreateDeviceRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDevice>> {
        if (requestParameters.kvintaOperationRequestCreateDeviceRequest === null || requestParameters.kvintaOperationRequestCreateDeviceRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateDeviceRequest','Required parameter requestParameters.kvintaOperationRequestCreateDeviceRequest was null or undefined when calling createDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createDevice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateDeviceRequestToJSON(requestParameters.kvintaOperationRequestCreateDeviceRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeviceFromJSON(jsonValue));
    }

    /**
     */
    async createDevice(requestParameters: CreateDeviceRequest): Promise<KvintaOperationResponseDevice> {
        const response = await this.createDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createMobileAppAssignmentRaw(requestParameters: CreateMobileAppAssignmentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMobileAppAssignment>> {
        if (requestParameters.kvintaOperationRequestCreateMobileAppAssignmentRequest === null || requestParameters.kvintaOperationRequestCreateMobileAppAssignmentRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestCreateMobileAppAssignmentRequest','Required parameter requestParameters.kvintaOperationRequestCreateMobileAppAssignmentRequest was null or undefined when calling createMobileAppAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/createMobileAssignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestCreateMobileAppAssignmentRequestToJSON(requestParameters.kvintaOperationRequestCreateMobileAppAssignmentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMobileAppAssignmentFromJSON(jsonValue));
    }

    /**
     */
    async createMobileAppAssignment(requestParameters: CreateMobileAppAssignmentRequest): Promise<KvintaOperationResponseMobileAppAssignment> {
        const response = await this.createMobileAppAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteDevice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteDevice(requestParameters: DeleteDeviceRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteMobileAppAssignmentRaw(requestParameters: DeleteMobileAppAssignmentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseInteger>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling deleteMobileAppAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/deleteMobileAssignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseIntegerFromJSON(jsonValue));
    }

    /**
     */
    async deleteMobileAppAssignment(requestParameters: DeleteMobileAppAssignmentRequest): Promise<KvintaOperationResponseInteger> {
        const response = await this.deleteMobileAppAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getApplicationsRaw(): Promise<runtime.ApiResponse<KvintaOperationResponseGetApplicationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/listApplications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetApplicationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getApplications(): Promise<KvintaOperationResponseGetApplicationsResponse> {
        const response = await this.getApplicationsRaw();
        return await response.value();
    }

    /**
     */
    async getDeviceRaw(requestParameters: GetDeviceRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDevice>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling getDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getDevice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeviceFromJSON(jsonValue));
    }

    /**
     */
    async getDevice(requestParameters: GetDeviceRequest): Promise<KvintaOperationResponseDevice> {
        const response = await this.getDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getMobileAppAssignmentRaw(requestParameters: GetMobileAppAssignmentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMobileAppAssignment>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling getMobileAppAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/getMobileAssignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMobileAppAssignmentFromJSON(jsonValue));
    }

    /**
     */
    async getMobileAppAssignment(requestParameters: GetMobileAppAssignmentRequest): Promise<KvintaOperationResponseMobileAppAssignment> {
        const response = await this.getMobileAppAssignmentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listDevicesRaw(requestParameters: ListDevicesRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultDevice>> {
        if (requestParameters.kvintaOperationRequestListDevicesRequest === null || requestParameters.kvintaOperationRequestListDevicesRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListDevicesRequest','Required parameter requestParameters.kvintaOperationRequestListDevicesRequest was null or undefined when calling listDevices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listDevices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListDevicesRequestToJSON(requestParameters.kvintaOperationRequestListDevicesRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultDeviceFromJSON(jsonValue));
    }

    /**
     */
    async listDevices(requestParameters: ListDevicesRequest): Promise<KvintaOperationResponseGetListResultDevice> {
        const response = await this.listDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listMobileAppAssignmentsRaw(requestParameters: ListMobileAppAssignmentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultMobileAppAssignment>> {
        if (requestParameters.kvintaOperationRequestListMobileAppAssignmentRequest === null || requestParameters.kvintaOperationRequestListMobileAppAssignmentRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListMobileAppAssignmentRequest','Required parameter requestParameters.kvintaOperationRequestListMobileAppAssignmentRequest was null or undefined when calling listMobileAppAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listMobileAssignments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListMobileAppAssignmentRequestToJSON(requestParameters.kvintaOperationRequestListMobileAppAssignmentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultMobileAppAssignmentFromJSON(jsonValue));
    }

    /**
     */
    async listMobileAppAssignments(requestParameters: ListMobileAppAssignmentsRequest): Promise<KvintaOperationResponseGetListResultMobileAppAssignment> {
        const response = await this.listMobileAppAssignmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendDownloadLinkRaw(requestParameters: SendDownloadLinkRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMobileAppAssignmentResponse>> {
        if (requestParameters.kvintaOperationRequestMobileAppAssignment === null || requestParameters.kvintaOperationRequestMobileAppAssignment === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestMobileAppAssignment','Required parameter requestParameters.kvintaOperationRequestMobileAppAssignment was null or undefined when calling sendDownloadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sendDownloadLink`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestMobileAppAssignmentToJSON(requestParameters.kvintaOperationRequestMobileAppAssignment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMobileAppAssignmentResponseFromJSON(jsonValue));
    }

    /**
     */
    async sendDownloadLink(requestParameters: SendDownloadLinkRequest): Promise<KvintaOperationResponseMobileAppAssignmentResponse> {
        const response = await this.sendDownloadLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateDeviceRaw(requestParameters: UpdateDeviceRequest): Promise<runtime.ApiResponse<KvintaOperationResponseDevice>> {
        if (requestParameters.kvintaOperationRequestDevice === null || requestParameters.kvintaOperationRequestDevice === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestDevice','Required parameter requestParameters.kvintaOperationRequestDevice was null or undefined when calling updateDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateDevice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestDeviceToJSON(requestParameters.kvintaOperationRequestDevice),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseDeviceFromJSON(jsonValue));
    }

    /**
     */
    async updateDevice(requestParameters: UpdateDeviceRequest): Promise<KvintaOperationResponseDevice> {
        const response = await this.updateDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateMobileAppAssignmentRaw(requestParameters: UpdateMobileAppAssignmentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseMobileAppAssignment>> {
        if (requestParameters.kvintaOperationRequestMobileAppAssignment === null || requestParameters.kvintaOperationRequestMobileAppAssignment === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestMobileAppAssignment','Required parameter requestParameters.kvintaOperationRequestMobileAppAssignment was null or undefined when calling updateMobileAppAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateMobileAssignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestMobileAppAssignmentToJSON(requestParameters.kvintaOperationRequestMobileAppAssignment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseMobileAppAssignmentFromJSON(jsonValue));
    }

    /**
     */
    async updateMobileAppAssignment(requestParameters: UpdateMobileAppAssignmentRequest): Promise<KvintaOperationResponseMobileAppAssignment> {
        const response = await this.updateMobileAppAssignmentRaw(requestParameters);
        return await response.value();
    }

}
