import { action, makeObservable, observable } from 'mobx';
import { TMapPointData, TPoint } from './types';

export class MovementMapStore {
  points: TPoint[];
  locations: TMapPointData[];
  activeMapPointId?: string;

  constructor(points: TPoint[], locations: TMapPointData[], activeMapPointId: string) {
    makeObservable(this, {
      points: observable,
      locations: observable,
      activeMapPointId: observable,

      setActiveMapPointId: action.bound,
    });
    this.points = points;
    this.locations = locations;
    this.activeMapPointId = activeMapPointId;
  }

  setActiveMapPointId(activePointId: string) {
    this.activeMapPointId = activePointId;
  }
}
